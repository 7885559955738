

a,
a:visited {
	color: $linkColor;
}

a:hover {
	color: $linkHoverColor;
}

::-moz-selection {
	background: $selectionColor;
	text-shadow: none;
}

::selection {
	background: $selectionColor;
	text-shadow: none;
}
/* HEADINGS / TYPOGRAPHY */
h1,
h2,
h3,
h4 {
	font-weight: normal;
	margin: 10px 0 10px;
}

h1 {}

h2 {
	font-size: 35px;
	color: $black;
}

h3 {}

h4 {}
/* STRUCTURE */
.container {
	max-width: $pageWidth;
	margin: 20px auto;
}


/* MAIN */
#first-time {
	display: none;
}
.secondary_content {
	background: $light-grey;
	.container {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
.below_content {
	display: inline-block;
  width: 100%;
  padding: 10px 0;
}
.secondary_content {
	display: inline-block;
  width: 100%;
  padding: 40px 0;
}
.cta_section  {
	background: $light-grey;
	display: inline-block;
  width: 100%;
}
.newsletter_signup {
	background: $dark-grey;
	display: inline-block;
	width: 100%;
	padding: 12px 0;
	height: 100px;
	.container {
		margin-top: 0;
		padding-top: 6px;
	}
	h2 {
		font-size: 20px;
		padding-left: 30px;
		font-weight: 800;
		text-transform: uppercase;
		font-family: $font-pt;
	}
	.block-block {
		display: inline-flex;
	}
}


.main-container {
	// breadcrumb
	.breadcrumb {
		background: transparent;
		padding: 0;
		font-size: 13px;

		> li {
			a {
				color: inherit;
				text-decoration: underline !important;
			}

			+ li {
				&:before {
					content: '> ';
					color: inherit;
					padding: 0 3px;
				}
			}
		}
	}
}