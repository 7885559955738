.footer {
  border-top: 0;
  background: $black-02;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 9px;
  h2 {
    color: $white;
    font-family: $font-pt;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.footer-content {
  height: 100%;
  margin-top: 0;
  padding-left: 40px;
  .menu {
      li {
        font-size: 14px;
        font-family: $font-proxima;
        a {
          color: $white;
          padding: 5px 10px;
        }
      }
  }
  .block {
    display: inline-block;
    color: $white;
    float: left;
    margin-right: 140px;
    &:last-child {
      margin-right: 0;
      width: 100%;
    }
  }
  .logos {

    p {
      display: inline-block;
    }

  }
  .horizontal__logos {
    margin-top: 20px;
    div {
      display: inline-block;
      float: left;
      padding-right: 130px;
    }
  }
}
.credit__logo {
  img {
    width: 70%;
  }
}
/* social icons */
#block-menu-menu-social-links {
  .menu {
    li {
      display: inline-block;
      font-size: 22px;
      margin-right: 34px;
      a {
        text-decoration: none;
        text-indent: -99999px;
        display: block;
        position: relative;
        margin-top: -30px;
        width: 20px;
        height: 20px;
      }
      &:nth-child(1){
        a:before {
          background: url('../images/fabook.png')no-repeat;
          content: '';
          position: absolute;
          height: 46px;
          width: 46px;
          display: block;
        }
      }
      &:nth-child(2){
        a:before {
          background: url('../images/twitter.png')no-repeat;
          content: '';
          position: absolute;
          height: 46px;
          width: 46px;
          display: block;
        }
      }
      &:nth-child(3){
        a:before {
          background: url('../images/youtube.png')no-repeat;
          content: '';
          position: absolute;
          height: 46px;
          width: 46px;
          display: block;
        }
      }
    }

  }

}

.copyright {
  background: $black-01;
  height: 35px;
  text-align: center;
  color: $white;
  font-family: $font-proxima;
  padding-top: 10px;
}
