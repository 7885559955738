
.navbar-default {
    background-color: inherit;
    border: none;
}
/***********
Nav Section
************/
.navbar-header {
    width: 100%;
}
header #navbar {
    height: 142px;
    padding: 0;
}
.navbar.container, .navbar.container-fluid {
    margin-top: 10px;
}
.nav__menu {
    background: $light-grey;
    height: 45px;
    font-size: 13px;
    font-family: $font-futura;

    > .container > .navbar-collapse {
        padding: 0;
    }

    .menu {
        li {
            text-transform: uppercase;
            font-size: 13px;
            font-family: $font-futura;
            font-weight: bold;
            a {
                color: $black-02;
                padding-top: 14px;
            }
            &.dropdown:hover {
                background-color: $gray-darker;
                // padding-bottom: 10px;
            }
            &.dropdown {
                // padding-bottom: 10px;
            }
        }

    }

    #search-instructions {
        display: inline-block;
        background: transparent;
        margin: 0 !important;
        padding: 0 !important;
        height: 45px;
        border: none;
        width: 190px;

        #block-search-form {
            display: inline-block;
            width: 100%;
            min-height: 0;
            height: 100%;
            font-family: $font-proxima;
            font-size: 13px;
            padding: 8px 0;

            div {
                height: 100%;
            }

            form {
                height: 100%;
                width: 100%;

                .input-group {
                    display: block;

                    input[type="text"] {
                        background: transparent url(../images/search-glass.png) no-repeat 7px center;
                        border: 1px solid rgba(#333, .47);
                        border-radius: 0;
                        color: #afafaf;
                        display: block;
                        height: 100%;
                        font-size: 13px;
                        padding: 3px 3px 3px 30px;
                        width: 100%;

                        &:focus, &:hover {
                            border-color: #333;
                            color: #333;
                            outline: none;
                            @include box-shadow(none);
                        }
                    }
                }

                .input-group-btn {
                    display: none;
                }
            }
        }

        .inst-text {
            display: none;
        }
    }
}
.caret {
    color: $blue;
    font-size: 18px;
    margin-left: 5px;
    margin-top: -3px
}
.nav {
    .open > a {
         &,
         &:hover,
         &:focus {
             background-color: $gray-darker;
            padding-bottom: 10px;
         }
     }
}

.navbar-nav > li >  {
    .dropdown-menu {
        background: $gray-darker;
        border: 0;
        width: 188px;
        border-radius: 0;
        li {
            padding: 7px;
            border-bottom: 1px solid $black-01;
            a {
                background: none;
                font-weight: 800;
            }
            a:hover {
                background: none;
                color: $black-01;
            }
            &:last-child {
                border:0;
            }
        }
    }
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: inherit;
}
.navbar-nav > li > a {
    padding-bottom: 10px;
}
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
    font-size: 44px;
    font-family: $font-pt;
    margin-right: 10px;
    margin-top: 46px;
    font-weight: 800;
    display: inline-block;
    color: $black-02;
}
.logo {
    margin-top: 25px;
}

.lead {
    font-size: 14px;
    font-family: $font-proxima;
    display: inline-block;
}
/***********
Second NAV
************/

.secondary {
    float: right;
    .service_top {
        margin-bottom: 32px;
        padding-left: 10px;
    }
}

/***************
Login Block
***************/
.not-logged-in {
    #block-drop-down-login-drop-down-login #drop-down-login-wrapper .dropdown {
        width: 265px;
        left: -123px;
    }
    #block-drop-down-login-drop-down-login {
        padding: 30px 70px;
            #user-login-form ul li {
                font-size: 14px;
            }
            .form-actions {
                display: inline-block;
                margin-top: 20px;
            }
            .btn-primary {
                background: $blue;
                padding: 20px;
            }
    }

}

#block-drop-down-login-drop-down-login {
    display: inline-block;
    vertical-align: middle;
    left: 0;

    @include bp(mobil) {
        left: 0;
    }
    &.active {
        background: $gray-darker;
    }
    #drop-down-login-wrapper .dropdown {
        top: 44px;
        left: -116px;
    }
    #drop-down-login-wrapper .button {
        background: none;
    }
}
#drop-down-login-wrapper .dropdown ul li {
    border-bottom: 0;
}
#drop-down-login-wrapper {
    ul.menu {
        text-align: center;
        padding: 0;
    }
    li {
        font-size: 16px;
        a {
            color: $white;
        }
    }
    li:last-child {
        background: $blue;
        padding: 8px;
        color: $white;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
    }
}
.drop-down-login-container {
    span {
        font-family: $font-pt;
        font-size: 18px;
        color: $black-02;
    }
    &:before {
        content:'';
        width: 48px;
        height: 48px;
        margin-left: -55px;
        margin-top: -15px;
        position: absolute;
        background: url('../images/user.png')no-repeat;
    }
    li {
        text-decoration: none;
        list-style: none;
    }
}
#user-login-form {
    ul {
        font-size: 16px;
        padding: 0;
        li:last-child {
            font-size: 16px;
        }
    }
}
.drop-down-login-container {
    a.login.button:after {
        color: #009bd3;
        content:'';
        position: absolute;
        left: 98px;
        font-size: 18px;
        margin-left: 12px;
        margin-top: -9px;
        vertical-align: middle;
        border-top: 8px dashed;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
    }
}
#drop-down-login-wrapper.enable-dd .button span span {
    background: none;
    text-transform: capitalize;


}
#drop-down-login-wrapper .dropdown {
    border: 0;
    border-radius: 0;
    background: $gray-darker;
    box-shadow: none;
    top: 49px;
    width: 300px;
    left: -106px;
    padding: 22px;
}

/***************
Cart Block
***************/
// #block-uc-cart-cart {
//     margin-top: 30px;
// }
// #block-uc-menu-cart-uc-menu-cart {
//     position: relative;
//     left: 40px;
//     margin-left: 15px;
//     display: inline-block;
//     padding: 30px 40px;
//     width: 150px;
//     top: 12px;
//     @include bp(mobil) {
//         display: block;
//         margin-bottom: 30px;

//     }
//     #drop-down-login-wrapper .button {
//         background: none;
//         span {
//             text-transform: capitalize;
//         }
//     }
//     &.active {
//         background: $gray-darker;
//     }
//     #drop-down-login-wrapper .dropdown {
//         width: 300px;
//         top:44px;
//         left: -141px;
//         @include bp(mobil) {
//             left: -106px;
//             margin-bottom: 20px;
//         }
//     }
//     .cart-block-item-title {
//         width: 60%;
//         border-right: 1px solid $black-02 !important;
//     }
//     .cart-block-items {
//         width: 250px;
//         margin: 5px;
//         background: $light-grey ;
//         border: solid 1px $black-02;
//         font-size: 15px;
//         font-family: $font-pt;
//         tbody {
//             display: inline-table;
//             margin: 10px;
//         }
//     }
//     .cart-block-item-price {
//         span {
//             font-size: 15px;
//         }
//         padding-left: 10px;
//     }
//     .cart-block-summary {
//         .cart-block-summary-items {
//             font-size: 25px;
//             top: 6px;
//             padding-right: 10px;
//             padding-left: 80px;
//             font-family: $font-pt;
//             position: absolute;
//             color: #fff;
//             font-weight: 800;
//             span {
//                 font-size: 25px;
//                 color: #fff;
//                 font-family: $font-pt;
//                 font-weight: 800;
//             }
//         }
//         .cart-block-summary-total {
//             padding-right: 50px;
//         }
//     }
//     .cart-block-summary-links {
//         ul.links {
//             text-align: center;
//             padding-left: 30px;
//         }
//         .cart-block-view-cart {
//             display: inline-block;
//             border: 0;
//             margin: 10px 0;
//             font-weight: 800;
//             a {
//                 color: #fff;
//             }
//         }
//         .cart-block-checkout {
//             display: inline-block;
//             width: 100px;
//         }
//     }
//     li.cart-block-view-cart {
//         color: #ffffff;
//         background:$blue;
//         border-radius: 8px;
//         font-size: 15px;
//         padding: 10px;
//         a {
//             color: #fff;
//         }
//         font-weight: 800;
//         text-transform: uppercase;
//     }
//     #drop-down-login-wrapper li:last-child {
//         background: none;
//         padding: 0;
//         font-size: 14px;
//     }
//     .cart-block-checkout {

//         a {
//             background: none;
//             padding: 5px;
//             text-transform: none;
//             font-size: 14px;
//         }
//     }
// }

/***************
Track Block
***************/

#block-qv-1 {
    width: 120px;
    display: inline-block;
    top: 0;
    position: relative;
    vertical-align: middle;

    * {
        vertical-align: middle;
    }

    h2 {
        color: $black-02;
        font-family: $font-futura;
        font-size: 18px;
        width: 180px;
        height: 72px;
        padding: 30px 45px 30px 10px;
        position: relative;
        left: -21px;
        cursor: pointer;
        font-weight: 800;
        text-align: right;
    }

    h2:before {
        content:'';
        width: 48px;
        height: 48px;
        margin-left: -50px;
        margin-top: -12px;
        position: absolute;
        background: url('../images/truck.png')no-repeat;
    }
    h2:after {
        color: #009bd3;
        content: '';
        position: absolute;
        right: 10px;
        font-size: 18px;
        margin-left: 12px;
        bottom: 25px;
        vertical-align: middle;
        border-top: 8px dashed;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
    }
    h2.active {
        background: $gray-darker;
    }
    #track-ui3 {
        width: 277px;
        height: 128px;
        display: none;
        &.display {
            display: block;
            position: absolute;
            z-index: 10;
            left:-21px;
            top: 82px;
        }
        padding: 25px 20px;
        background: $gray-darker;
        table {
            text-align: center;
             tr > td {
                 display: inline-block;
             }
        }
        #trackInput3 {
            width: 238px;
            height: 35px;
        }
        .my-btn {
            margin-top: 16px !important;
            background: $blue !important;
            background-image: inherit !important;
            font-size: 18px;
            display: inline-block;
            text-align: center;
            padding: 9px !important;
            width: 145px;
            color: $white !important;
            border-radius: 10px;
            text-transform: uppercase;
            font-weight: bold;
            a {
                color: $white;
                text-decoration: none;
            }
            &:hover {
                background: $btn-hover;
            }
        }
    }
}


// Cart Alt
#block-uc-ajax-cart-alt-uc-ajax-cart-alt {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 189px;
    top: 0;
    left: auto;
    padding-right: 40px;

    * {
        vertical-align: middle;
    }

    .drop-down-cart-container {
        .button {
            font-size: 17px;
            font-family: $font-futura;
            color: #333;

            .num-items {
                display: inline-block;
                font: inherit;

                div {
                    display: inline-block;
                    max-width: 20px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            &:after {
                left: auto;
                right: -15px;
                top: 18px;
            }
        }
    }

    &.active {
        background: $gray-darker;
    }
}