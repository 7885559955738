// Product Page
// @author: ReliQ (@IamReliQ)

%product-btn {
    background: #009bd3;
    border: none !important;
    border-radius: 10px;
    padding: 11px 15px !important;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

%product-accessories {
    clear: both;
    padding: 10px;
    margin: 10px 0;

    .field-items {
        text-align: center;
    }

    .field-item {
        display: inline-block;
        // max-width: 25%;
        // float: left;

        img {
            display: block;
            max-height: 100%;
            height: 130px;
            width: auto;
            padding: 0 5px;
        }
        
        &.screws {
            width: 50%;
        }
    }

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

%product-cart-area {
    .add-to-cart {
        table.add-to-cart-table {
            display: block;
            position: relative;
            
            td {
                display: block;
            }

            label {
                display: none;
            }

            .form-item-qty {
                margin: 0 0 20px;

                input[id*="edit-qty"] {
                    background: #f1f1f1;
                    border: none;
                    display: inline-block;
                    text-align: center;
                    font-size: 10px;
                    height: 45px;
                    width: 45px;
                    vertical-align: top;
                    margin: 0;
                }

                #minus-qty, #plus-qty, .minus-qty, .plus-qty {
                    background: #009bd3 center no-repeat;
                    cursor: pointer;
                    display: inline-block;
                    height: 45px;
                    width: 45px;
                }

                #minus-qty, .minus-qty {
                    background-image: url(../images/minus-qty.png);
                }

                #plus-qty, .plus-qty {
                    background-image: url(../images/plus-qty.png);
                }

                #total-qty, .total-qty {
                    display: block;
                    color: #000;
                    font-weight: bold;
                    font-size: 24px;
                    margin: 20px 0 0;

                    label {
                        display: inline-block;
                        margin: 0;
                    }
                }
            }

            .node-add-to-cart {
                @extend %product-btn;
                border: none !important;
                padding: 15px !important;
                font-size: 28px !important;
                min-width: 317px;
            }

            .uc-ajax-cart-alt-status-messages {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .form-item-product-sel {
            select.form-control {
                border-radius: 5px;
                border: 1px solid #999;
            }

            .qty-label {
                display: block;
                padding: 5px 0;
                font-size: 18px;
                text-align: center;
            }
        }
    }
}

#real-product-tax-term {
    background: #d5bcda;
    font-weight: bold;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    padding: 20px;
    font-family: $font-futura;
    font-size: 25px; 
}

.real-product-page.rq-product-page {
    #real-product-tax-term {
        display: none;
    }
    
    #product-title {
        font-weight: bold;
    }

    #product-images, .slide-area {
        margin-top: 29px;
        
        .field-slideshow-wrapper {
            position: relative;

            > .field-slideshow {
                position: relative;
                z-index: 1;
            }

            > .field-slideshow-controls {
                position: absolute;
                z-index: 3;
                top: 45%;
                width: 100%;

                > a {
                    background: transparent center no-repeat;
                    display: block;
                    width: 17px;
                    height: 52px;
                    color: transparent;
                    position: absolute;

                    &.prev {
                        background-image: url(../images/product-slideshow-left-arrow.png);
                    }

                    &.next {
                        background-image: url(../images/product-slideshow-right-arrow.png);
                        right: 0;
                    }
                }
            }

            > .field-slideshow-pager {
                text-align: center;
                padding: 10px 0;

                > a {
                    background: #c1c1c1;
                    border-radius: 50%;
                    color: transparent;
                    display: inline-block;
                    margin: 0 10px;
                    height: 21px;
                    width: 21px;

                    &.activeSlide {
                        background: #333;
                    }
                }
            }
        }
    }

    #stock-info {
        float: right;
        max-width: 340px;
        @include clearfix;

        table {
            margin-bottom: 40px !important;
            
            tr {
                background: #c1c1c1;
                font-weight: bold;
                color: #fff;

                td {
                    padding: 8px !important;

                    &:first-child {
                        text-transform: uppercase;
                    }

                    &:last-child {
                        color: #000;
                        margin-left: 20px;
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #fff;
                }
            }
        }

        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }

    #accessories {
        @extend %product-accessories;
    }

    #info-table {
        background: #f5f5f5;
        border-radius: 5px;
        border: 1px solid #000;
        font-size: 12px;
        margin: 30px 0;

        table {
            width: 100%;

            th, td {
                text-align: center;
                line-height: 1;
                padding: 5px;
                position: relative; 

                .display-price {
                    float: none;
                    padding: 0;
                    font-weight: normal;
                    font-size: inherit;
                }

                .field-items {
                    float: none;
                }

                &:not(:last-child) {
                    &:after {
                        background: #000;
                        content: "";
                        display: block;
                        height: 10px;
                        width: 1px;
                        position: absolute;
                        top: 24%;
                        right: 0;
                    }
                }
            }

            thead {
                border-bottom: 1px solid #000;
                font-weight: bold;
            }

            .field-label {
                display: none;
            }
        }
    }

    #product-cart-area {
        @extend %product-cart-area;

        .form-item-product-sel {
            display: none;
        }
    }

    #product-tabs {
        margin-bottom: 0;
        padding: 0;

        > .nav.nav-pills {
            background: #d5bcda;
            display: block;

            > li {
                width: 33.33%;
                margin: 0 !important;
                background: inherit;

                a {
                    background: inherit;
                    color: #000;
                    border-radius: 0;
                    padding: 20px;
                    display: block;
                    font-size: 25px;
                    font-family: $font-h;
                    font-weight: bold;
                    text-align: center;
                    text-transform: uppercase;
                }

                &.active {
                    a {
                        background-color: #eaddec;
                    }
                }

                &:not(:first-child) {
                   border-left: 1px solid #f1f1f1;
                }
            }
        }

        .tab-content {
            background: #f1f1f1;
            padding: 30px 40px;
            min-height: 373px;
            font-size: 15px;
            color: #000;

            .field-label {
                display: none;
            }

            .field-name-field-product-comments {
                margin-top: 30px;
            }

            .tab-pane {
                > [class*="download"] {
                    .field[class*="pdf"] {
                        display: none;
                    }

                    .download.btn {
                        @extend %product-btn;
                        background: #009bd3 url(../images/product-file-download.png) no-repeat 52px center;
                        border-radius: 20px;
                        padding: 10px 52px 10px 110px !important;
                        font-size: 14px !important;
                        letter-spacing: 0;
                        text-align: left;
                        white-space: initial;
                    }
                }

                &.dua {
                    text-align: center;

                    > div {
                        display: inline-block;
                        padding: 0 50px;
                        width: 50%;
                        vertical-align: middle;
                        text-align: center;

                        img {
                            display: inline-block;
                        }

                        &:first-child {
                            padding-left: 0;
                            text-align: right;
                        }

                        &:last-child {
                            padding-right: 0;
                            text-align: left;
                        }
                    }
                }

                &#installation-tab {
                    .installation-video {
                        width: 58%;

                        .field-item {
                            > * {
                                display: none;
                            }

                            iframe {
                                display: block;
                                max-width: 100%;
                                max-height: 270px;
                                width: 100%;
                            }
                        }
                    }

                    .installation-download {
                        width: 42%;

                        @media all and (max-width: 1000px) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    &.loading {
        opacity: 0.1;
    }
}