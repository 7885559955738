// responsive style
// Mobile Menu

.mean-container a.meanmenu-reveal span {
  background: $black-01;
  cursor: pointer;
}

.mean-container a.meanmenu-reveal {
  color: $black-01;
}

.mean-container .mean-bar {
  cursor: pointer;
  position: absolute;
  background: none;
  top: 165px;
}

#navbar {
  @include bp(mobil) {
    height: 100%;
  }
}

.nav__menu {
  @include bp(mobil) {
    display: none;
  }
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  @include bp(mobil) {
    font-size: 26px;
    position: absolute;
    margin-top: 34px;
  }
}

.lead {
  @include bp(mobil) {
    width: 200px;
    margin-left: 15px;
    position: relative;
    margin-top: 80px;
  }
}

.secondary {
  @include bp(mobil) {
    .service_top {
      font-size: 16px;
      margin-bottom: 45px;
    }

    .menu {
      margin: 25px 0 50px 50px;
      display: inline-block;
    }

    .menu li {
      padding-left: 0;
      padding-right: 24px;
    }

    .menu li.last {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}

.hero {
  @include bp(mobil) {
    min-height: 460px;
  }
}

.hero_section {
  @include bp(mobil) {
    #block-bean-hero-content {
      .field-name-field-description {
        margin-top: 30px;

        h1 {
          font-size: 30px;
        }

        h2 {
          font-size: 20px;
        }
      }

      p {
        width: 100%;
        font-size: 16px;
      }

      .field-name-field-cta-hero {
        font-size: 20px;
      }
    }
  }
}

.block-bean-about-us {
  .field-name-field-description-text {
    @include bp(mobil) {
      h2 {
        margin-top: 40px;
      }
    }
  }
}

#block-bean-about-us {
  .field-name-field-description-text {
    @include bp(mobil) {
      h2 {
        margin-top: 40px;
      }
    }
    @include bp(tablet) {
      h2 {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
  }
}

.cta_section {
  @include bp(mobil) {
    .block-bean {
      .field-name-field-text-cta {
        font-size: 30px;
      }
    }
  }
}

.newsletter_signup {
  @include bp(mobil) {
    height: 100%;

    .block-block {
      display: inline-block;
    }

    h2 {
      text-align: center;
      padding-left: 0;
    }
  }
}

.newsletter_text {
  @include bp(mobil) {
    padding-left: 20px;
    padding-bottom: 20px;
  }
}

.newsletter_block {
  @include bp(mobil) {
    .form-control {
      width: 200px;
    }
  }
}

#ccform {
  @include bp(mobil) {
    display: inline-flex;
  }
}

.email-btn button {
  @include bp(mobil) {
    width: 135px;
  }
}

.footer-content {
  @include bp(mobil) {
    height: 100%;
    text-align: center;
    padding-left: 0;
    .block {
      margin-right: 0;
      margin-bottom: 30px;
      display: block;
      float: inherit;
    }
    padding-bottom: 40px;

    .horizontal__logos div {
      display: inline-block;
      float: inherit;
      padding-right: 0;
      margin-top: 24px;
    }
  }
}

.copyright {
  @include bp(mobil) {
    height: 100%;
    padding: 20px 0;
  }
}
/** Custom media Query **/
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    font-size: 20px;
  }

  .lead {
    width: 165px;
    font-size: 12px;
  }

  .secondary .service_top {
    font-size: 13px;
  }

  .secondary .menu li a {
    font-size: 10px;
  }

  .hero_section {
    #block-bean-hero-content {
      .field-name-field-description {
        h1 {
          font-size: 25px;
        }

        h2 {
          font-size: 18px;
        }
      }

      p {
        width: 100%;
        font-size: 14px;
      }

      .field-name-field-cta-hero {
        font-size: 18px;
      }
    }
  }

  .newsletter_text {
    padding-left: 0;
    padding-right: 0;
  }

  .newsletter_block .form-control {
    width: 300px;
  }

  .email-btn button {
    width: 100%;
    margin-left: 0;
  }

  #ccform {
    display: inline-block;
  }
}
@media only screen and (min-device-width: 990px) and (max-device-width: 1200px) {
  .block-bean-about-us {
    .field-name-field-description-text {
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }

    .field-name-field-link-btn {
      margin-top: 28px;
    }
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 1199px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    font-size: 28px;
    margin-top: 34px;
  }

  .newsletter_signup {
    height: 100%;
  }

  .lead {
    width: 200px;
    margin-left: 15px;
    position: relative;
  }

  .secondary {
    .menu {
      margin-top: -4px;
      margin-left: 40px;

      li {
        padding-left: 11px;
        padding-right: 11px;

        a {
          font-size: 16px;
        }
      }

      li.last {
        padding-left: 35px;
        padding-right: 0;
      }
    }
  }

  .hero {
    min-height: 480px;
  }

  .hero_section {
    #block-bean-hero-content {
      .field-name-field-description {
        margin-top: 30px;

        h1 {
          font-size: 35px;
        }

        h2 {
          font-size: 25px;
        }
      }

      p {
        width: 100%;
        font-size: 20px;
      }

      .field-name-field-cta-hero {
        font-size: 25px;
      }
    }
  }

  .footer-content .block {
    margin-right: 40px;
  }
  .not-logged-in #block-drop-down-login-drop-down-login {
    padding: 20px 30px;
  }
  #block-drop-down-login-drop-down-login {
    width: 100px;
    top: -14px;
    left: 20px;
  }
  #block-drop-down-login-drop-down-login {
    left: 56px;
    display: inline-block;
    padding: 20px 20px;
    width: 100px;
    top: 0;
  }
  #block-drop-down-login-drop-down-login {
    width: 80px;
    #drop-down-login-wrapper .dropdown {
      top: 34px;
      left: -189px;
    }
  }
  #block-uc-menu-cart-uc-menu-cart {
    width: 120px;
    padding: 20px 40px;
    top:1px;
    left: 74px;
    #drop-down-login-wrapper .dropdown {
      top: 34px;
    }
  }
  #block-qv-1 #track-ui3.display {
    left: -118px;
  }

}
@media only screen and (min-device-width: 1200px) and (max-device-width: 1320px) {
  .region-header {
    position: relative;
    left: -77px;
  }
}
