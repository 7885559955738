// Resources Page
// @author: ReliQ (@IamReliQ)

%resource-btn {
    background: #009bd3;
    border: none !important;
    border-radius: 10px;
    padding: 11px 15px !important;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}


.resource-links {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;

    a.btn.full {
        @extend %resource-btn;
        min-height: 60px;
        margin: 20px 0;
        font-size: 20px !important;
        white-space: normal;
        min-height: 80px;
        width: 100%;
        
        &:hover {
            background: darken(#009bd3, 5%);
        }
    }
}