// Catalog Page
// @author: ReliQ (@IamReliQ)

%cat-item {
    background: #f1f1f1;
    border: none;
    min-height: 290px;
    text-align: center;
    border-radius: 12px;
    padding: 20px;
    margin: 10px;
}

body > .real-catalog-page.rq-catalog-page {
    #catalog-filters, .catalog-filters {
        display: block;
        text-align: center;
        font-family: $font-futura;
    }

    // both catalog and solar-cats
    [id*="solar-cats clearfix"], .view-uc-catalog, .view-uc-catalog-terms {
        display: block;
        margin: 15px 0;
        width: 100%;

        // solar cat table
        table#solar-cat-grid, table.views-view-grid {
            display: block;
            width: 100%;
            @include clearfix;

            tbody {
                display: block;

                tr {
                    display: block;
                    width: 100%;

                    td {
                        display: inline-block;
                        // width: 33%;
                    }
                }
            }
        }
        
        // catalog view
        .view-content, #solar-cat-grid > tbody, .views-view-grid > tbody {
            display: block;
            @include clearfix;
            margin: 0 -10px;
            
            .product-type, .solar-cats, > tr > td {
                @extend %cat-item;
                display: block;
                margin: 10px 1%;
                width: 31.33%;
                float: left;

                .node-product {
                    min-height: 400px;
                }

                .views-field-entity-id, .solar-cat > div:first-child, #product-images {
                    background: #fff;
                    display: block;
                    height: 167px;
                    overflow: hidden;

                    a, div {
                        display: block;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        min-height: 100%;
                    }
                }

                .product_title, .solar-cat > div:nth-child(2), .views-field-title, h2 {
                    font-family: $font-futura;
                    color: #000;
                    margin: 0;
                    padding: 15px 0;
                    font-size: 16px;

                    a {
                        color: inherit;
                        text-decoration: none;
                    }
                }

                #accessories, .accessories {
                    @extend %product-accessories;
                    height: 90px;

                    .field-item {
                        img {
                            height: 70px;
                            width: auto;
                            padding: 0 5px;
                        }
                    }
                }

                #product-cart-area, .product-cart-area {
                    @extend %product-cart-area;

                    .add-to-cart {
                        table.add-to-cart-table {
                            text-align: center;

                            *, td {
                                display: inline-block;
                                vertical-align: middle;
                            }

                            .form-item-qty {
                                margin: 0;

                                input[id*="edit-qty"] {
                                    background: #fff;
                                }
                            }

                            .node-add-to-cart {
                                background: #009bd3 url(../images/node-add-to-cart.png) center/50% no-repeat;
                                border-radius: 0;
                                cursor: pointer;
                                color: transparent !important;
                                display: inline-block;
                                height: 45px;
                                width: 45px;
                                border: none !important;
                                margin-left: 10px;
                                padding: 0 !important;
                                font-size: inherit !important;
                                min-width: 0;
                            }
                        }
                    }
                }
            }
        }


    }
}