// Variables
//
// Set variables for this site before a library sets its !default value.
//
// Style guide: sass.variables
// Font Face
// @include fontface('FONT_NAME', 'assets/fonts/', 'FONT_FAMILY');

// Structure
$pageWidth: 1440px;

// Text Selection
$selectionColor: orange;

// Links
$linkColor: #5bc0c3;
$linkHoverColor: #5bc0c3;

// Colors
$white        :#ffffff;
$light-grey   :#f1f1f1;
$blue         :#009bd3;
$yellow       :#fcd703;
$light-yellow :#f7da64;
$orange-light :#fec38b;
$orange       :#f37f03;
$dark-grey    :#d6d6d6;
$ligth-blue   :#22acea;
$green        :#a8d384;
$red          :#f16855;
$gray-darker  :#d6d6d6;
$purple       :#d5bcda;
$black        :#000;
$black-01     :#191919;
$black-02     :#333333;
$btn-hover    :#0076A0;


$caret-width-base: 8px;
// Fonts
$font-pt     : 'PT Sans', sans-serif;
$font-proxima: 'Proxima Nova';
$font-default: $font-proxima, 'Open Sans', sans-serif;
$font-futura: 'Futura PT Heavy', sans-serif;
$font-h: $font-futura;
$header-font: $font-h;
