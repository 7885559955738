// Fonts

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/proxima-nova-webfont.eot');
	src: local('☺'), url('../fonts/proxima-nova-webfont.woff') format('woff'), url('../fonts/proxima-nova-webfont.ttf') format('truetype'), url('../fonts/proxima-nova-webfont.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Futura PT Heavy';
	src: url('../fonts/futura-pt-heavy-5949b0d7670ab.eot');
	src: local('☺'), url('../fonts/futura-pt-heavy-5949b0d7670ab.woff') format('woff'), url('../fonts/futura-pt-heavy-5949b0d7670ab.ttf') format('truetype'), url('../fonts/futura-pt-heavy-5949b0d7670ab.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

