/*hero section */
.hero_section {
  display: inline-block;
  width: 100%;
    .block-bean {
        width: 100%;
        padding: 0;
    }

    .container {
        margin-bottom: 0;
        margin-top: 0;
    }
    #block-bean-hero-content {
      color: $white;
    	h1 {
        font-size: 50px;
        margin-top: 80px;
        text-transform: uppercase;
      }
      .field-name-field-description {
        padding-bottom: 10px;
        margin-top: 80px;
        h1 {
          font-weight: bold;
          margin-top: 20px;
          margin-left: 34px;
          font-size: 45px;
        }
        h2 {
          margin-left: 34px;
          color: $white;
          font-size: 30px;
        }
        &:before {
          content:'';
          width: 20%;
          height: 8px;
          background: $yellow;
          position: relative;
          display: block;
          padding-top: 7px;
          margin-left: 40px;
        }
      }
      p {
        padding: 20px 40px;
        width: 70%;
        font-size: 18px;
      }
      .field-name-field-cta-hero {
        margin-left: 40px;
        padding: 5px 40px;
        font-size: 40px;
        text-transform: uppercase;
        display: inline-block;
        background: $yellow;
        a {
          text-decoration: none;
          color:$orange;
        }
      }
    }
}

.hero {
    width: 100%;
    min-height: 535px;
    margin: 0 auto;
    position: relative;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}

/**
Product type
*/
.front {
  #block-views-uc-catalog-terms-block-1 {
    h2 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 20px;
    }
    .product-type {
      display: inline-block;
      margin-left: 7px;
      margin-right: 10px;
      width: 142px;
      margin-bottom: 5px;
      border: solid 4px
    }
    .product-type:nth-child(1){
      border-color:$red;
    }
    .product-type:nth-child(2){
      border-color:$orange-light;
    }
    .product-type:nth-child(3){
      border-color:$green;
    }
    .product-type:nth-child(4){
      border-color:$ligth-blue;
    }
    .product-type:nth-child(5){
      border-color:$yellow;
    }
    .all-products {
      float: left;
      display: inline-block;
      width: 145px;
      height: 142px;
      background: #999999;
      margin-left: 7px;
      margin-right: 10px;
      span {
        width: 120px;
        margin-top: 30px;
        color: $white;
        position: relative;
        font-weight: 800;
        display: inline-block;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        margin-left: 10px;

      }
    }
    .views-row-last {
      width: 143px;
      height: 142px;
      background: #999999;
      border: none;
      margin-left: 7px;
      .product_title {
        top: 0;
        border: 0;
        margin-left: 8px;
      }
    }
    .views-field-name {
      position: absolute;
    }
    .product_title {
      position: absolute;
      top: -138px;
      overflow: hidden;
      text-align: center;
      text-transform: uppercase;
      width: 120px;
      margin-left: 10px;
      margin-top: 36px;
      font-size: 16px;
      font-weight: 800;
      a {
        color: $white;
      }
    }
    margin-bottom: 20px;
  }

}


.block-bean-about-us {
  font-size: 16px;
  font-family: $font-pt;
  color:$black-02;
  .field-name-field-description-text {
    h2 {
      font-size: 40px;
      margin-top: 0;
      text-transform: uppercase;
      margin-bottom: 35px;
      font-weight: bold;
      font-family: $font-pt;
    }
    p {
      font-size: 14px;
      font-family: $font-proxima;
    }
  }
  .field-name-field-link-btn {
    background: $blue;
    margin-top: 40px;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    padding: 5px;
    width: 184px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    a {
      color: $white;
      text-decoration: none;
    }
    &:hover {
      background: $btn-hover;
    }
  }
}
.cta_section {
  padding: 40px 0;
  .block-bean {
    text-align: center;
    .field-name-field-text-cta {
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .field-name-field-link-cta {
      display: inline-block;
      background: $blue;
      padding: 15px;
      font-weight: bold;
      font-size: 18px;
      border-radius: 10px;
      text-transform: uppercase;
      a {
        color: $white;
        text-decoration: none;
      }
      &:hover {
        background: $btn-hover;
      }
    }
  }
}
/***************
Custumer Reviews
***************/

.below_content {
  .block-title {
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 800;
  }
}
/***************
Newsletter block
***************/
.newsletter_block {
  .form-control {
    width: 320px;
    border-radius: 0;
  }
}
.email-btn {
  button {
    width: 145px;
    height: 34px;
    margin-left: 18px;
    font-family: $font-proxima;
    background:$blue;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
      background: $btn-hover;
    }
  }
}
.newsletter_text {
  font-size: 14px;
  font-family: $font-proxima;
  padding-left: 85px;
  padding-right: 39px;
}
