/*
 * Mixin for easy media queries
 * @include bp(tablet) { SCSS here };
*/
@mixin bp($point) {
	@if $point == large {
		@media (max-width : 1600px) { @content; }
	}
	@if $point == tablet {
		@media (max-width : 1024px) { @content; }
	}
	@else if $point == mobil {
		@media (max-width : 768px) { @content; }
	}
}

/*
 * Mixin for micro clearfix
 * @include microclearfix;
*/
@mixin microclearfix {
	&:before, &:after { content: ""; display: table; }
	&:after { clear: both; }
	*zoom: 1;
}

/*
 * Mixin for basic CSS triangles
 * @include triangle(up, #000, 50px)
*/
@mixin triangle($direction:up, $color:#000, $size:100px) {
	@if($direction == up) {
		border-color: transparent transparent $color;
		border-style: solid;
		border-width: 0 $size $size;
		height: 0;
		width: 0;
	}
	@if($direction == down) {
		border-color: $color transparent transparent transparent;
		border-style: solid;
		border-width: $size;
		height:0;
		width:0;
	}
	@if($direction == left) {
		border-color: transparent $color transparent transparent;
		border-style: solid;
		border-width: $size $size $size 0;
		height: 0;
		width: 0;
	}
	@if($direction == right) {
		border-color: transparent transparent transparent $color;
		border-style: solid;
		border-width: $size 0 $size $size;
		height:0;
		width:0;
	}
}


@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('../fonts/proxima-nova-webfont.eot');
    src: url('../fonts/proxima-nova-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima-nova-webfont.woff2') format('woff2'),
         url('../fonts/proxima-nova-webfont.woff') format('woff'),
         url('../fonts/proxima-nova-webfont.ttf') format('truetype'),
         url('../fonts/proxima-nova-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
